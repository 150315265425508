@import "app/styles/screens";

$grid-columns: 12;
$grid-width: 1200px;
$grid-md-width: 884px;
$grid-gutter: 20px;

@function calc-col-width($cols) {
  @return calc((($grid-width / $grid-columns) * $cols / $grid-width) * 100%);
}

%container {
  margin-left: auto;
  margin-right: auto;
  max-width: $grid-width;
  padding-left: $grid-gutter;
  padding-right: $grid-gutter;

  @include small-and-medium {
    max-width: $grid-md-width;
  }
}

.container {
  @extend %container;
}

.containerGutter {
  padding-left: $grid-gutter;
  padding-right: $grid-gutter;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}

// Spacing classes
.marginTop0 {
  margin-top: 0;
}
.marginButtom0 {
  margin-bottom: 0;
}
.marginHorizontal0 {
  margin-left: 0;
  margin-right: 0;
}
.paddingHorizontal0 {
  padding-left: 0;
  padding-right: 0;
}
