@import "app/styles/colors";
@import "app/styles/fonts";
@import "app/styles/misc";
@import "app/rescript/styleguide/forms/FormField";
@import "app/rescript/styleguide/components/Icon/Icon";

.wrapper {
  display: flex;
  position: relative;
  flex: 1;

  &:first-of-type > .input.grouped {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &:last-of-type > .input.grouped {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-right: 1px solid $input-field-border-color;
  }
}

.input {
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: $input-field-border-color;
  border-radius: $input-field-border-radius;
  padding: $input-field-v-padding $input-field-h-padding;
  transition-property: border-color, box-shadow;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;
  color: $text-color;
  font-size: $font-size-base;
  font-variant: normal;
  font-stretch: normal;
  font-family: $font-family-base;
  font-weight: $font-weight-base;
  font-style: normal;
  background-color: #fff;
  &::placeholder {
    color: rgba($text-color, 0.5);
  }

  &:focus {
    border: 1px solid $input-field-border-color-active;
    outline: none;
  }

  &.disabled {
    color: $input-placeholder-color;
    background-color: $input-disabled-bg-color;
  }

  &.error {
    border: 1px solid $pink;
  }

  &.withRightSideAddon {
    padding-right: calc($md + $input-field-h-padding * 2);
  }

  &.withLeftSideAddon {
    padding-left: calc($md + $input-field-h-padding);
  }

  &.grouped {
    border-radius: 0;
    border-right: none;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
}

.icon {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  pointer-events: none;
}

.leftIcon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  pointer-events: none;
}
